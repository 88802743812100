/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * ktcs-api
 * ktcs-api
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  LoopbackCount,
  MasterSanPham,
  MasterSanPhamControllerCountParams,
  MasterSanPhamControllerFindByIdParams,
  MasterSanPhamControllerFindParams,
  MasterSanPhamPartial,
  MasterSanPhamWithRelations,
  NewMasterSanPham,
} from '../../models';
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';

export const masterSanPhamControllerCount = (params?: MasterSanPhamControllerCountParams, signal?: AbortSignal) => {
  return drcInstance<LoopbackCount>({ url: `/master-san-phams/count`, method: 'GET', params, signal });
};

export const getMasterSanPhamControllerCountQueryKey = (params?: MasterSanPhamControllerCountParams) => {
  return [`/master-san-phams/count`, ...(params ? [params] : [])] as const;
};

export const getMasterSanPhamControllerCountQueryOptions = <
  TData = Awaited<ReturnType<typeof masterSanPhamControllerCount>>,
  TError = ErrorType<unknown>,
>(
  params?: MasterSanPhamControllerCountParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof masterSanPhamControllerCount>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMasterSanPhamControllerCountQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof masterSanPhamControllerCount>>> = ({ signal }) =>
    masterSanPhamControllerCount(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MasterSanPhamControllerCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof masterSanPhamControllerCount>>
>;
export type MasterSanPhamControllerCountQueryError = ErrorType<unknown>;

export const useMasterSanPhamControllerCount = <
  TData = Awaited<ReturnType<typeof masterSanPhamControllerCount>>,
  TError = ErrorType<unknown>,
>(
  params?: MasterSanPhamControllerCountParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof masterSanPhamControllerCount>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMasterSanPhamControllerCountQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const masterSanPhamControllerUpdateById = (id: string, masterSanPhamPartial: MasterSanPhamPartial) => {
  return drcInstance<unknown>({
    url: `/master-san-phams/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: masterSanPhamPartial,
  });
};

export const getMasterSanPhamControllerUpdateByIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerUpdateById>>,
    TError,
    { id: string; data: MasterSanPhamPartial },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof masterSanPhamControllerUpdateById>>,
  TError,
  { id: string; data: MasterSanPhamPartial },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof masterSanPhamControllerUpdateById>>,
    { id: string; data: MasterSanPhamPartial }
  > = (props) => {
    const { id, data } = props ?? {};

    return masterSanPhamControllerUpdateById(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MasterSanPhamControllerUpdateByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof masterSanPhamControllerUpdateById>>
>;
export type MasterSanPhamControllerUpdateByIdMutationBody = MasterSanPhamPartial;
export type MasterSanPhamControllerUpdateByIdMutationError = ErrorType<unknown>;

export const useMasterSanPhamControllerUpdateById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerUpdateById>>,
    TError,
    { id: string; data: MasterSanPhamPartial },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof masterSanPhamControllerUpdateById>>,
  TError,
  { id: string; data: MasterSanPhamPartial },
  TContext
> => {
  const mutationOptions = getMasterSanPhamControllerUpdateByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const masterSanPhamControllerFindById = (
  id: string,
  params?: MasterSanPhamControllerFindByIdParams,
  signal?: AbortSignal,
) => {
  return drcInstance<MasterSanPhamWithRelations>({ url: `/master-san-phams/${id}`, method: 'GET', params, signal });
};

export const getMasterSanPhamControllerFindByIdQueryKey = (
  id: string,
  params?: MasterSanPhamControllerFindByIdParams,
) => {
  return [`/master-san-phams/${id}`, ...(params ? [params] : [])] as const;
};

export const getMasterSanPhamControllerFindByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof masterSanPhamControllerFindById>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: MasterSanPhamControllerFindByIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof masterSanPhamControllerFindById>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMasterSanPhamControllerFindByIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof masterSanPhamControllerFindById>>> = ({ signal }) =>
    masterSanPhamControllerFindById(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerFindById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MasterSanPhamControllerFindByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof masterSanPhamControllerFindById>>
>;
export type MasterSanPhamControllerFindByIdQueryError = ErrorType<unknown>;

export const useMasterSanPhamControllerFindById = <
  TData = Awaited<ReturnType<typeof masterSanPhamControllerFindById>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: MasterSanPhamControllerFindByIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof masterSanPhamControllerFindById>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMasterSanPhamControllerFindByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const masterSanPhamControllerDeleteById = (id: string) => {
  return drcInstance<unknown>({ url: `/master-san-phams/${id}`, method: 'DELETE' });
};

export const getMasterSanPhamControllerDeleteByIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerDeleteById>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof masterSanPhamControllerDeleteById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof masterSanPhamControllerDeleteById>>, { id: string }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return masterSanPhamControllerDeleteById(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type MasterSanPhamControllerDeleteByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof masterSanPhamControllerDeleteById>>
>;

export type MasterSanPhamControllerDeleteByIdMutationError = ErrorType<unknown>;

export const useMasterSanPhamControllerDeleteById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerDeleteById>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof masterSanPhamControllerDeleteById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getMasterSanPhamControllerDeleteByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const masterSanPhamControllerCreate = (newMasterSanPham: NewMasterSanPham) => {
  return drcInstance<MasterSanPham>({
    url: `/master-san-phams`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: newMasterSanPham,
  });
};

export const getMasterSanPhamControllerCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerCreate>>,
    TError,
    { data: NewMasterSanPham },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof masterSanPhamControllerCreate>>,
  TError,
  { data: NewMasterSanPham },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof masterSanPhamControllerCreate>>,
    { data: NewMasterSanPham }
  > = (props) => {
    const { data } = props ?? {};

    return masterSanPhamControllerCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MasterSanPhamControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof masterSanPhamControllerCreate>>
>;
export type MasterSanPhamControllerCreateMutationBody = NewMasterSanPham;
export type MasterSanPhamControllerCreateMutationError = ErrorType<unknown>;

export const useMasterSanPhamControllerCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerCreate>>,
    TError,
    { data: NewMasterSanPham },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof masterSanPhamControllerCreate>>,
  TError,
  { data: NewMasterSanPham },
  TContext
> => {
  const mutationOptions = getMasterSanPhamControllerCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const masterSanPhamControllerFind = (params?: MasterSanPhamControllerFindParams, signal?: AbortSignal) => {
  return drcInstance<MasterSanPhamWithRelations[]>({ url: `/master-san-phams`, method: 'GET', params, signal });
};

export const getMasterSanPhamControllerFindQueryKey = (params?: MasterSanPhamControllerFindParams) => {
  return [`/master-san-phams`, ...(params ? [params] : [])] as const;
};

export const getMasterSanPhamControllerFindQueryOptions = <
  TData = Awaited<ReturnType<typeof masterSanPhamControllerFind>>,
  TError = ErrorType<unknown>,
>(
  params?: MasterSanPhamControllerFindParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof masterSanPhamControllerFind>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMasterSanPhamControllerFindQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof masterSanPhamControllerFind>>> = ({ signal }) =>
    masterSanPhamControllerFind(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof masterSanPhamControllerFind>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MasterSanPhamControllerFindQueryResult = NonNullable<
  Awaited<ReturnType<typeof masterSanPhamControllerFind>>
>;
export type MasterSanPhamControllerFindQueryError = ErrorType<unknown>;

export const useMasterSanPhamControllerFind = <
  TData = Awaited<ReturnType<typeof masterSanPhamControllerFind>>,
  TError = ErrorType<unknown>,
>(
  params?: MasterSanPhamControllerFindParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof masterSanPhamControllerFind>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMasterSanPhamControllerFindQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
