// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.tabs-wrapper {
  display: flex;
  justify-content: center; /* Căn giữa các tab trong container */
  align-items: center; /* Căn giữa theo chiều dọc nếu cần */
}

.nav-tabs-scrollable {
  display: flex;
  flex-wrap: nowrap; /* Ngăn các tab xuống hàng */
  white-space: nowrap; /* Đảm bảo tab không bị cắt */
}

.nav-tabs-scrollable .nav-item {
  flex: 1 1;
  text-align: center; /* Căn giữa nội dung của từng tab */
}

.nav-tabs {
  display: flex;
  justify-content: center; /* Căn giữa các tab */
  flex-wrap: nowrap; /* Ngăn các tab xuống hàng */
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.row-updated .dsg-cell {
  /* Orange */
  background: #fff7e6;
}
`, "",{"version":3,"sources":["webpack://./src/template/assets/css/CustomGrid.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0CAA0C;EAC1C,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,qCAAqC;EAC9D,mBAAmB,EAAE,oCAAoC;AAC3D;;AAEA;EACE,aAAa;EACb,iBAAiB,EAAE,4BAA4B;EAC/C,mBAAmB,EAAE,6BAA6B;AACpD;;AAEA;EACE,SAAO;EACP,kBAAkB,EAAE,mCAAmC;AACzD;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,qBAAqB;EAC9C,iBAAiB,EAAE,4BAA4B;AACjD;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".loading-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 9999;\n}\n\n.tabs-wrapper {\n  display: flex;\n  justify-content: center; /* Căn giữa các tab trong container */\n  align-items: center; /* Căn giữa theo chiều dọc nếu cần */\n}\n\n.nav-tabs-scrollable {\n  display: flex;\n  flex-wrap: nowrap; /* Ngăn các tab xuống hàng */\n  white-space: nowrap; /* Đảm bảo tab không bị cắt */\n}\n\n.nav-tabs-scrollable .nav-item {\n  flex: 1;\n  text-align: center; /* Căn giữa nội dung của từng tab */\n}\n\n.nav-tabs {\n  display: flex;\n  justify-content: center; /* Căn giữa các tab */\n  flex-wrap: nowrap; /* Ngăn các tab xuống hàng */\n}\n\nbutton:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n\n.row-updated .dsg-cell {\n  /* Orange */\n  background: #fff7e6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
