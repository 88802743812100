// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Example__container__document .react-pdf__Page {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.Example__container__document .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
  `, "",{"version":3,"sources":["webpack://./src/template/assets/css/CustomPDF.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;AACA;IACI,aAAa;IACb,sCAAsC;EACxC","sourcesContent":[".Example__container__document .react-pdf__Page {\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);\n}\n.Example__container__document .react-pdf__Page {\n    margin: 1em 0;\n    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
