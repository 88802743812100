import classnames from 'classnames';
import { useAuthenticationControllerChangePassword } from 'main/apis/drc/endpoints/authentication-controller/authentication-controller';
import page from 'main/constants/page';
import { AuthenticationUtil } from 'main/utils';
import React from 'react';
import ReactNotificationAlert, { AlertOptions } from 'react-notification-alert';
import { useNavigate } from 'react-router-dom';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Media,
  Nav,
  Modal,
  NavItem,
  Button,
  Row,
  Col,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap';

type Props = {
  theme: 'dark' | 'light';
  sidenavOpen: boolean;
  toggleSidenav: () => void;
};

function AdminNavbar({
  theme = 'dark',
  sidenavOpen = false,
  toggleSidenav = () => {
    // do nothing
  },
}: Readonly<Props>) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [passwordOld, setPasswordOld] = React.useState('');
  const [valPasswordOld, setValPasswordOld] = React.useState(false);
  const [passwordNew, setPasswordNew] = React.useState('');
  const [valPasswordNew, setValPasswordNew] = React.useState(false);
  const [passwordReNew, setPasswordReNew] = React.useState('');
  const [valPasswordReNew, setValPasswordReNew] = React.useState(false);
  const notificationAlertRef = React.useRef<ReactNotificationAlert | null>(null);

  const notify = (
    type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark',
    message: string,
  ) => {
    if (notificationAlertRef.current) {
      const options: AlertOptions = {
        place: 'tc',
        message,
        type: type,
        icon: 'ni ni-bell-55',
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(options);
    } else {
      console.error('NotificationAlert reference is null.');
    }
  };

  const navigate = useNavigate();

  const { mutate } = useAuthenticationControllerChangePassword();

  const handleChangePassword = () => {
    if (passwordNew === passwordReNew) {
      mutate(
        {
          data: {
            newPass: passwordNew,
            oldPass: passwordOld,
          },
        },
        {
          onSuccess: (data) => {
            setdefaultModal(false);
            notify('success', 'Đổi mật khẩu thành công !');
          },
          onError: (error) => {
            console.error('Error changing password:', error);
            notify('danger', 'Lưu sản phẩm thất bại! Lỗi ' + error);
            setdefaultModal(false);
          },
        },
      );
      setPasswordOld('');
      setPasswordNew('');
      setPasswordReNew('');
    }
  };

  const handleLogout = () => {
    navigate(page.LOGIN.PATH);
    AuthenticationUtil.deleteAuthToken();
  };

  return (
    <Navbar
      className={classnames(
        'navbar-top navbar-expand border-bottom',
        { 'navbar-dark bg-info': theme === 'dark' },
        { 'navbar-light bg-secondary': theme === 'light' },
      )}>
      <Container fluid>
        <div className="rna-wrapper">
          <ReactNotificationAlert ref={notificationAlertRef} />
        </div>
        <Collapse navbar isOpen={true}>
          <Form
            className={classnames(
              'navbar-search form-inline mr-sm-3',
              { 'navbar-search-light': theme === 'dark' },
              { 'navbar-search-dark': theme === 'light' },
            )}>
            <h2 className="text-white">CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG (DRC)</h2>
          </Form>

          <Nav className="align-items-center ml-md-auto" navbar>
            <NavItem className="d-xl-none">
              <div
                className={classnames(
                  'pr-3 sidenav-toggler',
                  { active: sidenavOpen },
                  { 'sidenav-toggler-dark': theme === 'dark' },
                )}
                onClick={toggleSidenav}>
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </NavItem>
          </Nav>

          <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="nav-link pr-0" color="" tag="a">
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="avatar" src={`${process.env.PUBLIC_URL}/assets/img/logo-drc.png`} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold cursor-pointer">
                      {AuthenticationUtil.getUsername()?.toUpperCase()}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right>
                {/* <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Xin chào!</h6>
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-single-02" />
                  <span>Thông tin của tôi</span>
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Thiết lập</span>
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Hoạt động</span>
                </DropdownItem> */}
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-key" />
                  <span onClick={() => setdefaultModal(true)}>Đổi mật khẩu</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span onClick={handleLogout}>Đăng xuất</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Modal className="modal-dialog-centered" isOpen={defaultModal} toggle={() => setdefaultModal(false)}>
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Đổi mật khẩu
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setdefaultModal(false)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body pt-0 pb-0">
              <Row>
                <Col md="12">
                  <label className="form-control-label" htmlFor="validationCustom04">
                    Mật khẩu cũ
                  </label>
                  <Input
                    id="validationCustom04"
                    placeholder="Mật khẩu cũ"
                    type="password"
                    value={passwordOld}
                    valid={valPasswordOld}
                    invalid={!valPasswordOld}
                    onChange={(e) => {
                      setPasswordOld(e.target.value);
                      if (e.target.value === '') {
                        setValPasswordOld(false);
                      } else {
                        setValPasswordOld(true);
                      }
                    }}
                  />
                  <div className="invalid-feedback">Vui lòng nhập mật khẩu cũ.</div>
                </Col>
                <Col md="12">
                  <label className="form-control-label mt-3" htmlFor="validationCustom05">
                    Mật khẩu mới
                  </label>
                  <Input
                    id="validationCustom05"
                    placeholder="Nhập mật khẩu mới"
                    type="password"
                    value={passwordNew}
                    valid={valPasswordNew}
                    invalid={!valPasswordNew}
                    onChange={(e) => {
                      setPasswordNew(e.target.value);
                      if (e.target.value === '') {
                        setValPasswordNew(false);
                      } else {
                        setValPasswordNew(true);
                      }
                    }}
                  />
                  <div className="invalid-feedback">Vui lòng nhập mật khẩu mới.</div>
                </Col>
                <Col md="12">
                  <label className="form-control-label mt-3" htmlFor="validationCustom05">
                    Nhập lại mật khẩu mới
                  </label>
                  <Input
                    id="validationCustom05"
                    placeholder="Nhập mật khẩu mới"
                    type="password"
                    value={passwordReNew}
                    valid={valPasswordReNew}
                    invalid={!valPasswordReNew}
                    onChange={(e) => {
                      setPasswordReNew(e.target.value);
                      if (e.target.value === '' || e.target.value !== passwordNew) {
                        setValPasswordReNew(false);
                      } else {
                        setValPasswordReNew(true);
                      }
                    }}
                  />
                  <div className="invalid-feedback">Vui lòng nhập lại mật khẩu không trùng khớp.</div>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button color="primary" type="button" onClick={handleChangePassword}>
                Đồng ý
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => setdefaultModal(false)}>
                Huỷ
              </Button>
            </div>
          </Modal>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
