const Page = {
  /** Dashboard Pages */
  DASHBOARD: {
    NAME: 'Dashboard',
    PATH: '/admin/dashboard',
  },
  /** Nhập liệu tiêu chuẩn  */
  PhongKyThuatCaoSu: {
    TITLE: 'Phòng kỹ thuật cao su',
    NAME: 'Phòng kỹ thuật cao su',
    PATH: '/admin/phong-ky-thuat-cao-su',
  },
  /** Nhập liệu tiêu chuẩn  */
  PKTCS_NhapLieuTieuChuan: {
    TITLE: 'Phòng kỹ thuật cao su',
    NAME: 'Nhập liệu tiêu chuẩn',
    PATH: '/admin/phong-ky-thuat-cao-su/nhap-lieu-tieu-chuan',
  },
  /** Authentication Pages */
  LOGIN: {
    TITLE: 'Đăng nhập',
    NAME: 'Đăng nhập',
    PATH: '/auth/login',
  },
  /** Authentication Pages */
  USER_MANAGEMENT: {
    TITLE: 'Quản lý người dùng',
    NAME: 'Quản lý người dùng',
    PATH: '/users',
  },
  /** Error Pages */
  SYSTEM_ERROR: {
    NAME: 'Lỗi',
    PATH: '/error/system-error',
  },
  URL_NOT_FOUND: {
    NAME: 'Lỗi',
    PATH: '/error/url-not-found',
  },
  ACCESS_DENIED: {
    NAME: 'Lỗi',
    PATH: '/error/access-denied',
  },
};

export default Page;
