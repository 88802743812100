/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * ktcs-api
 * ktcs-api
 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import type {
  AuthenticationControllerChangePassword200,
  AuthenticationControllerChangePasswordBody,
  AuthenticationControllerLogin200,
  Credentials,
} from '../../models';
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';

export const authenticationControllerChangePassword = (
  authenticationControllerChangePasswordBody: AuthenticationControllerChangePasswordBody,
) => {
  return drcInstance<AuthenticationControllerChangePassword200>({
    url: `/users/changePassword`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: authenticationControllerChangePasswordBody,
  });
};

export const getAuthenticationControllerChangePasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticationControllerChangePassword>>,
    TError,
    { data: AuthenticationControllerChangePasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticationControllerChangePassword>>,
  TError,
  { data: AuthenticationControllerChangePasswordBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticationControllerChangePassword>>,
    { data: AuthenticationControllerChangePasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return authenticationControllerChangePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationControllerChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticationControllerChangePassword>>
>;
export type AuthenticationControllerChangePasswordMutationBody = AuthenticationControllerChangePasswordBody;
export type AuthenticationControllerChangePasswordMutationError = ErrorType<unknown>;

export const useAuthenticationControllerChangePassword = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticationControllerChangePassword>>,
    TError,
    { data: AuthenticationControllerChangePasswordBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authenticationControllerChangePassword>>,
  TError,
  { data: AuthenticationControllerChangePasswordBody },
  TContext
> => {
  const mutationOptions = getAuthenticationControllerChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authenticationControllerLogin = (credentials: Credentials) => {
  return drcInstance<AuthenticationControllerLogin200>({
    url: `/users/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: credentials,
  });
};

export const getAuthenticationControllerLoginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticationControllerLogin>>,
    TError,
    { data: Credentials },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticationControllerLogin>>,
  TError,
  { data: Credentials },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticationControllerLogin>>,
    { data: Credentials }
  > = (props) => {
    const { data } = props ?? {};

    return authenticationControllerLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationControllerLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticationControllerLogin>>
>;
export type AuthenticationControllerLoginMutationBody = Credentials;
export type AuthenticationControllerLoginMutationError = ErrorType<unknown>;

export const useAuthenticationControllerLogin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticationControllerLogin>>,
    TError,
    { data: Credentials },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authenticationControllerLogin>>,
  TError,
  { data: Credentials },
  TContext
> => {
  const mutationOptions = getAuthenticationControllerLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
