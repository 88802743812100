// import Dashboard from 'main/components/pages/admin/dashboard/dashboard';
import PKTCS_NhapLieuTieuChuan from 'main/components/pages/admin/phong-ky-thuat-cao-su/nhap-lieu-tieu-chuan';
import { Page } from 'main/constants';
import AdminLayout from 'main/layouts/admin';
import { Navigate, Route } from 'react-router-dom';
import PrivateRoute from './private-route';

const adminRouter = (
  <Route
    path="/admin/*"
    element={
      <PrivateRoute>
        <AdminLayout />
      </PrivateRoute>
    }>
    {/** Dashboard Routes */}
    {/* <Route path="dashboard" element={<Dashboard />} /> */}

    {/** Dashboard Routes */}
    <Route path="phong-ky-thuat-cao-su/nhap-lieu-tieu-chuan" element={<PKTCS_NhapLieuTieuChuan />} />

    {/** Other Routes */}
    <Route path="*" element={<Navigate to={Page.PKTCS_NhapLieuTieuChuan.PATH} replace />} />
  </Route>
);

export default adminRouter;
