import { useResizeObserver } from '@wojtekmaj/react-hooks';
import classnames from 'classnames';
import _ from 'lodash';
import { useMasterSanPhamControllerFind } from 'main/apis/drc/endpoints/master-san-pham-controller/master-san-pham-controller';
import {
  useTongHopTieuChuanControllerGetAllChiTieu,
  useTongHopTieuChuanControllerInsertUpdateVersion,
  useTongHopTieuChuanControllerGetTieuChuanByMaSanPham,
  useTongHopTieuChuanControllerUploadFile,
  useTongHopTieuChuanControllerCopyTieuChuan,
} from 'main/apis/drc/endpoints/tong-hop-tieu-chuan-controller/tong-hop-tieu-chuan-controller';
import * as models from 'main/apis/drc/models';
import BasicTemplate from 'main/components/templates/basic-template';
import * as Constants from 'main/constants';
import React, { useState, useCallback, useEffect } from 'react';
import { DataSheetGrid, checkboxColumn, textColumn, keyColumn, floatColumn } from 'react-datasheet-grid';
import { pdfjs, Document, Page } from 'react-pdf';
import Select2 from 'react-select2-wrapper';
import { ClipLoader } from 'react-spinners';
import {
  Input,
  Card,
  Modal,
  CardBody,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import 'template/assets/css/CustomPDF.css';
import 'react-datasheet-grid/dist/style.css';
import 'template/assets/css/CustomGrid.css';
import ReactNotificationAlert, { AlertOptions } from 'react-notification-alert';

// PDF
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};
const resizeObserverOptions = {};
const maxWidthPDF = 800;

function PKTCS_NhapLieuTieuChuan() {
  /**
   *Init
   */
  const [enableBodyInsert, setEnableBodyInsert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [infoProduct, setInfoProduct] = useState('');
  const [file, setFile] = React.useState<Blob>();
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = React.useState<number>();
  const [fileLoaded, setFileLoaded] = React.useState(false);
  const [optionsSanPham, setOptionsSanPham] = useState<models.MasterSanPham[]>([]);
  const [optionSanPham, setOptionSanPham] = useState('');
  const [optionSanPhamCopy, setOptionSanPhamCopy] = useState('');
  const [optionsPDF, setOptionsPDF] = useState([
    {
      id: '',
      text: 'Tiêu chuẩn TC - Chưa có pdf',
    },
    {
      id: '',
      text: 'Lưu hoá - Chưa có pdf',
    },
  ]);
  const [optionPDF, setOptionPDF] = useState('');
  const [fileTctc, setFileTctc] = useState('');
  const [selectedRadio, setSelectedRadio] = React.useState('TC'); // Default value là "TC"
  const [fileLuuHoa, setFileLuuHoa] = useState('');
  const [urlUpload, setUrlUpload] = useState('');
  const [tonghopChiTieu, setTonghopChiTieu] = useState<models.AllChiTieu[]>([]);
  const [activeTab, setActiveTab] = useState('');
  const [ngayApDung, setNgayApDung] = useState('');
  const [phienBan, setPhienBan] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [fetchDataTieuChuan, setFetchDataTieuChuan] = useState(false);
  const [fetchDataTieuChuanCopy, setFetchDataTieuChuanCopy] = useState(false);
  const [defaultModal, setdefaultModal] = React.useState(false);
  const visibleTabsCount = 7; // Số lượng tab hiển thị cùng lúc
  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);
  useResizeObserver(containerRef, resizeObserverOptions, onResize);
  const [tabs, setTabs] = useState([
    {
      id: '',
      name: '',
      data: [{}],
    },
  ]);
  const [columns] = useState([
    { ...keyColumn('name', textColumn), title: 'Tên chỉ tiêu', width: 1, grow: 2, disabled: true },
    { ...keyColumn('giaTri', floatColumn), title: 'Giá trị', minWidth: 100 },
    { ...keyColumn('lechDuoi', floatColumn), title: 'Lệch dưới', minWidth: 100 },
    { ...keyColumn('lechTren', floatColumn), title: 'Lệch trên', minWidth: 100 },
    { ...keyColumn('type', checkboxColumn), title: 'Ngoại quan', minWidth: 120 },
  ]);
  const notificationAlertRef = React.useRef<ReactNotificationAlert | null>(null);

  const notify = (
    type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark',
    message: string,
  ) => {
    if (notificationAlertRef.current) {
      const options: AlertOptions = {
        place: 'tc',
        message,
        type: type,
        icon: 'ni ni-bell-55',
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(options);
    } else {
      console.error('NotificationAlert reference is null.');
    }
  };
  /**
   *API
   */
  const { data: dataMasterSanPham, isLoading: isLoading1 } = useMasterSanPhamControllerFind({
    filter: { fields: { maSanPham: true, tenGoiNho: true, tenSanPham: true } },
  });
  const { data: dataMasterTongHopChiTieu, isLoading: isLoading2 } = useTongHopTieuChuanControllerGetAllChiTieu();
  const { mutate: uploadFile } = useTongHopTieuChuanControllerUploadFile();
  const { mutate: copyTieuChuan } = useTongHopTieuChuanControllerCopyTieuChuan({
    mutation: {
      onSuccess: (data) => {
        // setFetchDataTieuChuan(true);
        setFetchDataTieuChuanCopy(true); // Kích hoạt việc gọi API
        setdefaultModal(false);
        // setOptionSanPhamCopy('-1');
        layDuLieuCu();
      },
      onError: (error) => {
        notify('danger', 'Copy sản phẩm thất bại! Lỗi ' + error);
        setLoading(false);
      },
    },
  });

  const { data: dataMasterTongHopTieuChuan, refetch } = useTongHopTieuChuanControllerGetTieuChuanByMaSanPham(
    encodeURIComponent(optionSanPham),
    {
      query: {
        enabled: fetchDataTieuChuan && Boolean(optionSanPham), // Chỉ gọi API khi fetchData = true
      },
    },
  );

  const { data: dataMasterTongHopTieuChuanCopy, refetch: refetchCopy } =
    useTongHopTieuChuanControllerGetTieuChuanByMaSanPham(encodeURIComponent(optionSanPhamCopy), {
      query: {
        enabled: fetchDataTieuChuanCopy && Boolean(optionSanPhamCopy), // Chỉ gọi API khi fetchData = true
      },
    });

  const mutationInsertUpdateVersion = useTongHopTieuChuanControllerInsertUpdateVersion({
    mutation: {
      onSuccess: (data) => {
        notify('success', 'Lưu sản phẩm thành công!');
        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 1000);
      },
      onError: (error) => {
        notify('danger', 'Lưu sản phẩm thất bại! Lỗi ' + error);
        setLoading(false);
      },
    },
  });

  /**
   *Watch
   */
  useEffect(() => {
    if (dataMasterTongHopChiTieu) {
      setTonghopChiTieu(
        _.sortBy(dataMasterTongHopChiTieu, [
          function (o) {
            return o.priority;
          },
        ]),
      );
    }

    if (dataMasterSanPham) {
      setOptionsSanPham(dataMasterSanPham);
    }
  }, [dataMasterTongHopChiTieu, dataMasterSanPham]);

  useEffect(() => {
    //Wait APi loading
    if (!isLoading1 && !isLoading2) {
      setFetchDataTieuChuan(false);
      setLoading(false);
    }
  }, [isLoading1, isLoading2]);

  useEffect(() => {
    if (urlUpload) {
      setTimeout(() => {
        luuTieuChuan();
      }, 1000);
    }
  }, [urlUpload]);

  useEffect(() => {
    if (!_.isEmpty(dataMasterTongHopTieuChuan)) {
      const maVersion = _.get(dataMasterTongHopTieuChuan, 'version');
      const date = _.get(dataMasterTongHopTieuChuan, 'date');
      const fileTctctmp = _.get(dataMasterTongHopTieuChuan, 'fileTctc');
      const fileLuuHoatmp = _.get(dataMasterTongHopTieuChuan, 'fileLuuHoa');
      const updatedTabs = tabs.map((itemB) => {
        const updatedData = itemB.data.map((dataItem: any) => {
          const matchingA: any = _.find(dataMasterTongHopTieuChuan.items, function (o: any) {
            return o.maChiTieu === dataItem.id;
          });
          if (matchingA) {
            return {
              ...dataItem,
              giaTri: matchingA.giaTri,
              lechDuoi: matchingA.lechDuoi,
              lechTren: matchingA.lechTren,
              // eslint-disable-next-line eqeqeq
              type: matchingA.type == 1 ? true : false,
            };
          }
          return dataItem; // Giữ nguyên nếu không khớp
        });

        return {
          ...itemB,
          data: updatedData,
        };
      });
      setOptionsPDF([
        {
          id: '',
          text: 'Tiêu chuẩn TC - Chưa có pdf',
        },
        {
          id: '',
          text: 'Lưu hoá - Chưa có pdf',
        },
      ]);
      setFileLoaded(false);
      setEnableBodyInsert(true);
      toggleTab(1);
      setNgayApDung(date ? date?.split('T')[0] : '');
      setPhienBan(maVersion ? maVersion : '/');
      setInfoProduct(
        'Ngày áp dụng: ' + (date ? date?.split('T')[0] : '/') + ' - Phiên bản: ' + (maVersion ? maVersion : '/'),
      );
      setFileTctc(fileTctctmp || '');
      setFileLuuHoa(fileLuuHoatmp || '');
      setTabs(updatedTabs);
      setOptionsPDF([
        {
          id: process.env.REACT_APP_DRC_API_HOST + (fileTctctmp ?? ''),
          text: 'Tiêu chuẩn TC - ' + (fileTctctmp?.split('/')[2] || 'Chưa có pdf'),
        },
        {
          id: process.env.REACT_APP_DRC_API_HOST + (fileLuuHoatmp ?? ''),
          text: 'Lưu hoá - ' + (fileLuuHoatmp?.split('/')[2] || 'Chưa có pdf'),
        },
      ]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      let tabsTemp: any[];
      tabsTemp = [];
      // eslint-disable-next-line array-callback-return
      tonghopChiTieu.map((o) => {
        let data: any[];
        data = [];
        // eslint-disable-next-line array-callback-return
        o.items?.map((i, index) => {
          data.push({
            ghiChu: null,
            giaTri: 0,
            id: i.id,
            lechDuoi: i.doLechMin,
            lechTren: i.doLechMin,
            type: false,
            name: i.name,
          });
        });
        tabsTemp.push({
          id: o.id || '',
          name: o.name || '',
          data: data,
        });
      });
      setTabs(tabsTemp);
      setOptionsPDF([
        {
          id: '',
          text: 'Tiêu chuẩn TC - Chưa có pdf',
        },
        {
          id: '',
          text: 'Lưu hoá - Chưa có pdf',
        },
      ]);
      setLoading(false);
      setNgayApDung('');
      setPhienBan('/');
      setInfoProduct('Ngày áp dụng: / - Phiên bản: /');
      setFileTctc('');
      setFileLuuHoa('');
      setOptionsPDF([
        {
          id: process.env.REACT_APP_DRC_API_HOST || '',
          text: 'Tiêu chuẩn TC - Chưa có pdf',
        },
        {
          id: process.env.REACT_APP_DRC_API_HOST || '',
          text: 'Lưu hoá - Chưa có pdf',
        },
      ]);
    }
    setFetchDataTieuChuan(false);
  }, [dataMasterTongHopTieuChuan]);

  useEffect(() => {
    if (!_.isEmpty(dataMasterTongHopTieuChuanCopy)) {
      const maVersion = _.get(dataMasterTongHopTieuChuanCopy, 'version');
      const date = _.get(dataMasterTongHopTieuChuanCopy, 'date');
      const fileTctctmp = _.get(dataMasterTongHopTieuChuanCopy, 'fileTctc');
      const fileLuuHoatmp = _.get(dataMasterTongHopTieuChuanCopy, 'fileLuuHoa');
      const updatedTabs = tabs.map((itemB) => {
        const updatedData = itemB.data.map((dataItem: any) => {
          const matchingA: any = _.find(dataMasterTongHopTieuChuanCopy.items, function (o: any) {
            return o.maChiTieu === dataItem.id;
          });
          if (matchingA) {
            return {
              ...dataItem,
              giaTri: matchingA.giaTri,
              lechDuoi: matchingA.lechDuoi,
              lechTren: matchingA.lechTren,
              // eslint-disable-next-line eqeqeq
              type: matchingA.type == 1 ? true : false,
            };
          }
          return dataItem; // Giữ nguyên nếu không khớp
        });

        return {
          ...itemB,
          data: updatedData,
        };
      });

      setEnableBodyInsert(true);
      toggleTab(1);
      setNgayApDung(date ? date?.split('T')[0] : '');
      setPhienBan(maVersion ? maVersion : '/');
      setInfoProduct(
        'Ngày áp dụng: ' + (date ? date?.split('T')[0] : '/') + ' - Phiên bản: ' + (maVersion ? maVersion : '/'),
      );
      setOptionsPDF([
        {
          id: '',
          text: 'Tiêu chuẩn TC - Chưa có pdf',
        },
        {
          id: '',
          text: 'Lưu hoá - Chưa có pdf',
        },
      ]);
      setFileTctc(fileTctctmp || '');
      setFileLuuHoa(fileLuuHoatmp || '');
      setTabs(updatedTabs);
      setOptionsPDF([
        {
          id: process.env.REACT_APP_DRC_API_HOST + (fileTctctmp ?? ''),
          text: 'Tiêu chuẩn TC - ' + (fileTctctmp?.split('/')[2] || 'Chưa có pdf'),
        },
        {
          id: process.env.REACT_APP_DRC_API_HOST + (fileTctctmp ?? ''),
          text: 'Lưu hoá - ' + (fileLuuHoatmp?.split('/')[2] || 'Chưa có pdf'),
        },
      ]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      notify('success', 'Sao chép sản phẩm thành công!');
    } else {
      let tabsTemp: any[];
      tabsTemp = [];
      // eslint-disable-next-line array-callback-return
      tonghopChiTieu.map((o) => {
        let data: any[];
        data = [];
        // eslint-disable-next-line array-callback-return
        o.items?.map((i, index) => {
          data.push({
            ghiChu: null,
            giaTri: 0,
            id: i.id,
            lechDuoi: i.doLechMin,
            lechTren: i.doLechMin,
            type: false,
            name: i.name,
          });
        });
        tabsTemp.push({
          id: o.id || '',
          name: o.name || '',
          data: data,
        });
      });
      setTabs(tabsTemp);
      setLoading(false);
      setNgayApDung('');
      setPhienBan('/');
      setInfoProduct('Ngày áp dụng: / - Phiên bản: /');
      setFileTctc('');
      setFileLuuHoa('');
      setOptionsPDF([
        {
          id: process.env.REACT_APP_DRC_API_HOST || '',
          text: 'Tiêu chuẩn TC - Chưa có pdf',
        },
        {
          id: process.env.REACT_APP_DRC_API_HOST || '',
          text: 'Lưu hoá - Chưa có pdf',
        },
      ]);
    }
    setFetchDataTieuChuanCopy(false);
  }, [dataMasterTongHopTieuChuanCopy]);

  useEffect(() => {
    if (tonghopChiTieu) {
      let tabsTemp: any[];
      tabsTemp = [];
      // eslint-disable-next-line array-callback-return
      tonghopChiTieu.map((o) => {
        let data: any[];
        data = [];
        // eslint-disable-next-line array-callback-return
        o.items?.map((i, index) => {
          data.push({
            ghiChu: null,
            giaTri: 0,
            id: i.id,
            lechDuoi: i.doLechMin,
            lechTren: i.doLechMin,
            type: false,
            name: i.name,
          });
        });
        tabsTemp.push({
          id: o.id || '',
          name: o.name || '',
          data: data,
        });
      });
      setTabs(tabsTemp);
    }
  }, [tonghopChiTieu]);

  /**
   *Function
   */
  // PFD
  function onFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { files } = event.target;
    const nextFile = files?.[0];
    setOptionPDF('-1');
    if (nextFile) {
      setFile(nextFile);
      setLoading(true);
      setFileLoaded(false);
      // Gọi mutation để upload file
      uploadFile(
        {
          data: {
            file: nextFile,
          },
        },
        {
          onSuccess: (data) => {
            setUrlUpload(data?.url || '');
          },
          onError: (error) => {
            console.error('Error uploading file', error);
            setLoading(false);
          },
        },
      );
    }
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setFileLoaded(true);
  }

  // Data Grid
  const toggleTab = (tabId: any) => {
    setActiveTab(tabId);
  };
  const handleNext = () => {
    if (startIndex + visibleTabsCount < tabs.length) {
      setStartIndex(startIndex + 1);
    }
  };
  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };
  const visibleTabs = tabs.slice(startIndex, startIndex + visibleTabsCount);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetTabs = useCallback(
    _.debounce((updatedTabs) => {
      setTabs(updatedTabs);
    }, 10),
    [],
  );

  const luuTieuChuan = () => {
    setLoading(true);
    let time = new Date().toISOString().split('T')[1];
    // Dữ liệu cần insert hoặc update
    const newVersionData: {
      createTime: string;
      createUser: string;
      date: string;
      fileLuuHoa: string;
      fileTctc: string;
      items: any[]; // Thay đổi kiểu thành any[]
      maSanPham: string;
      updateTime: string;
      updateUser: string;
      version: string;
    } = {
      createTime: new Date().toISOString(),
      createUser: 'adminUser',
      date: ngayApDung + 'T' + time,
      fileLuuHoa: urlUpload ? (selectedRadio !== 'TC' ? urlUpload : fileLuuHoa) : fileLuuHoa,
      fileTctc: urlUpload ? (selectedRadio === 'TC' ? urlUpload : fileTctc) : fileTctc,
      items: [], // Sẽ được cập nhật bên dưới
      maSanPham: optionSanPham,
      updateTime: new Date().toISOString(),
      updateUser: 'adminUser',
      version: phienBan,
    };
    const allItemsTabs = _.flatMap(tabs, (section) => section.data);

    const transformedAllItemsTabs = allItemsTabs.map((o: any) => {
      const { id, type, name, ghiChu, ...rest } = o; // Destructure với kiểu any
      return {
        maChiTieu: id, // Chuyển đổi id thành maChiTieu
        type: type ? 1 : 0, // Chuyển đổi type từ true/false thành 1/0
        maSanPham: optionSanPham, // Sử dụng biến optionSanPham
        ghiChu: null,
        ...rest, // Giữ lại các thuộc tính khác (giaTri, lechDuoi, lechTren)
      };
    });

    // Cập nhật items trong newVersionData
    newVersionData.items = transformedAllItemsTabs;

    // Gọi mutation
    mutationInsertUpdateVersion.mutate({ data: newVersionData });
    setSelectedRadio('TC');
  };

  const layDuLieuCu = () => {
    setLoading(true);
    setFetchDataTieuChuan(true); // Kích hoạt việc gọi API\
    setEnableBodyInsert(true);
    toggleTab(1);
  };

  const handleCopyTieuChuan = () => {
    setLoading(true);
    const bodyData = {
      data: {
        maSanPhamFrom: optionSanPhamCopy, // Giá trị maSanPhamFrom
        maSanPhamTo: optionSanPham, // Giá trị maSanPhamTo
      },
    };
    copyTieuChuan(bodyData);
  };

  const [scale, setScale] = useState(1); // Default scale is 1

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1); // Increase scale
  };

  const zoomOut = () => {
    setScale((prevScale) => (prevScale > 0.1 ? prevScale - 0.1 : prevScale)); // Decrease scale
  };

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default scroll behavior
    const zoomAmount = 0.1;

    if (event.deltaY < 0) {
      // Mouse scroll up
      setScale((prevScale) => prevScale + zoomAmount);
    } else {
      // Mouse scroll down
      setScale((prevScale) => (prevScale > zoomAmount ? prevScale - zoomAmount : prevScale));
    }
  };
  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      {loading && (
        <div className="loading-container">
          <Row className="d-flex justify-content-center align-items-center text-center text-justify">
            <Col md="12">
              <ClipLoader color="#00BFFF" loading={loading} size={180} />
            </Col>
            <Col md="12" className="mt-2">
              <p>Đang tải dữ liệu, vui lòng chờ...</p>
            </Col>
          </Row>
        </div>
      )}
      {!loading && (
        <BasicTemplate
          pageTitle={Constants.Page.PKTCS_NhapLieuTieuChuan.TITLE}
          pageName={Constants.Page.PKTCS_NhapLieuTieuChuan.NAME}
          parentPage={{
            name: Constants.Page.PhongKyThuatCaoSu.NAME,
            path: Constants.Page.PhongKyThuatCaoSu.PATH,
          }}>
          <Container className="mt--6" fluid>
            <Card className="mb-4">
              <CardBody className="p-4">
                {!enableBodyInsert && (
                  <div>
                    <Row>
                      <Col md="12">
                        <FormGroup className="row">
                          <Label className="form-control-label" htmlFor="example-search-input" md="2">
                            Mã sản phẩm
                          </Label>
                          <Col md="10">
                            <Select2
                              className="form-control"
                              defaultValue="-1"
                              value={optionSanPham}
                              options={{ placeholder: 'Chọn sản phẩm' }}
                              data={optionsSanPham.map((item) => ({ id: item.maSanPham, text: item.tenSanPham }))}
                              onSelect={(e: any) => {
                                setOptionSanPham(e.params.data.id);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="12"></Col>
                    </Row>
                    <Row>
                      <Col lg="6" xs="7"></Col>
                      <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                        <Button color="primary" onClick={layDuLieuCu} outline type="button">
                          Lấy dữ liệu
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
                {enableBodyInsert && (
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="example-search-input" md="2">
                              Mã sản phẩm
                            </Label>
                            <Col md="10">
                              <Select2
                                className="form-control"
                                defaultValue="-1"
                                value={optionSanPham}
                                options={{ placeholder: 'Chọn sản phẩm' }}
                                data={optionsSanPham.map((item) => ({ id: item.maSanPham, text: item.tenSanPham }))}
                                onSelect={(e: any) => {
                                  setOptionSanPham(e.params.data.id);
                                  layDuLieuCu();
                                  setFile(undefined);
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="row">
                            <Label className="form-control-label" md="2">
                              Thông tin
                            </Label>
                            <Col md="10">
                              <Input type="text" disabled value={infoProduct} />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                    <Col lg="6" xs="7"></Col>
                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                      <Button color="primary" onClick={layDuLieuCu} outline type="button">
                        Lấy dữ liệu
                      </Button>
                    </Col>
                  </Row> */}
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="row">
                            <Col md="12">
                              <div className="custom-file">
                                <input
                                  accept=".pdf"
                                  className="custom-file-input"
                                  id="customFileLang"
                                  lang="en"
                                  type="file"
                                  onChange={onFileChange}
                                />
                                <label className="custom-file-label" htmlFor="customFileLang"></label>
                              </div>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" xs="7">
                          <Row>
                            <Col md="6">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  defaultChecked
                                  id="customRadio6"
                                  name="custom-radio-1"
                                  type="radio"
                                  value="TC"
                                  onChange={(e) => setSelectedRadio(e.target.value)} // Cập nhật trạng thái khi thay đổi
                                />
                                <label className="custom-control-label" htmlFor="customRadio6">
                                  Tiêu chuẩn TC
                                </label>
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="customRadio5"
                                  name="custom-radio-1"
                                  type="radio"
                                  value="LuuHoa"
                                  onChange={(e) => setSelectedRadio(e.target.value)} // Cập nhật trạng thái khi thay đổi
                                />
                                <label className="custom-control-label" htmlFor="customRadio5">
                                  Lưu hoá
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                          <Button color="primary" onClick={handleUpload} outline type="button">
                            Tải lên PDF
                          </Button>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            {enableBodyInsert && (
              <Row className="d-flex align-items-stretch">
                <Col md="12">
                  <div className="content-box">
                    <Card>
                      <CardBody className="p-1">
                        <Row>
                          <Col md="1">
                            <Button
                              size="sm"
                              className="btn btn-outline-primary m-0 btn-block mt-1"
                              onClick={handlePrevious}
                              disabled={startIndex === 0}>
                              <b> {'<'}</b>
                            </Button>
                          </Col>
                          <Col md="10" className="p-0">
                            <Nav tabs className="m-0">
                              {visibleTabs.map((tab) => (
                                <NavItem key={tab.id}>
                                  <NavLink
                                    className={classnames({ active: activeTab === tab.id })}
                                    onClick={() => {
                                      toggleTab(tab.id);
                                    }}>
                                    {tab.name}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                          </Col>
                          <Col md="1" className="border-1">
                            <Button
                              size="sm"
                              className="btn btn-outline-primary m-0 btn-block mt-1"
                              onClick={handleNext}
                              disabled={startIndex + visibleTabsCount >= tabs.length}>
                              <b> {'>'}</b>
                            </Button>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col sm="12">
                            <TabContent activeTab={activeTab}>
                              {tabs.map((tab) => (
                                <TabPane key={tab.id} tabId={tab.id}>
                                  <h4>Bảng {tab.name}</h4>
                                  <DataSheetGrid
                                    lockRows
                                    value={tab.data} // Hiển thị dữ liệu của tab hiện tại
                                    columns={columns} // Truyền các cột vào bảng
                                    onChange={(newValue) => {
                                      setTabs((prevData) => {
                                        const index = prevData.findIndex((form) => form.id === activeTab);

                                        // Nếu không tìm thấy hoặc dữ liệu không thay đổi, không cập nhật
                                        if (index === -1 || _.isEqual(prevData[index].data, newValue)) {
                                          return prevData;
                                        }

                                        // Tìm và thay thế các giá trị null trong newValue
                                        const updatedValue = newValue.map((row) => {
                                          return Object.fromEntries(
                                            Object.entries(row).map(([key, value]) => [
                                              key,
                                              value === null ? 0 : value,
                                            ]), // Set null thành 0 hoặc giá trị khác nếu cần
                                          );
                                        });

                                        // Chỉ tạo một mảng mới và thay đổi phần tử cần thiết
                                        const newTabs = [...prevData];
                                        newTabs[index] = { ...newTabs[index], data: updatedValue };

                                        // Sử dụng debounced function để cập nhật tabs
                                        debouncedSetTabs(newTabs);

                                        return prevData;
                                      });
                                    }} // Xử lý khi có thay đổi
                                  />
                                </TabPane>
                              ))}
                            </TabContent>
                          </Col>
                        </Row>
                        <div className="row justify-content-between mt-3 mb-3">
                          <Col md="6">
                            <FormGroup className="row ml-2">
                              <Label className="form-control-label" htmlFor="example-date-input" md="4">
                                Ngày áp dụng
                              </Label>
                              <Col md="8">
                                <Input
                                  value={ngayApDung}
                                  onChange={(o) => {
                                    const selectedDate = o.target.value; // Lấy giá trị ngày đã chọn
                                    setNgayApDung(selectedDate); // Cập nhật giá trị ngày
                                  }}
                                  id="example-date-input"
                                  type="date"
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="row mr-2">
                              <Label className="form-control-label" htmlFor="example-date-input" md="4">
                                Phiên bản
                              </Label>
                              <Col md="8">
                                <Input
                                  value={phienBan}
                                  onChange={(o) => {
                                    const selectedVersion = o.target.value; // Lấy giá trị ngày đã chọn
                                    setPhienBan(selectedVersion); // Cập nhật giá trị ngày
                                  }}
                                  type="text"
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                          <Button color="primary" className="ml-3" outline onClick={() => setdefaultModal(true)}>
                            Sao chép
                          </Button>
                          <Button color="primary" className="mr-3" onClick={luuTieuChuan}>
                            Lưu tiêu chuẩn
                          </Button>
                        </div>
                        <Modal
                          className="modal-dialog-centered"
                          isOpen={defaultModal}
                          toggle={() => setdefaultModal(false)}>
                          <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                              Sao chép tiêu chuẩn từ
                            </h6>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => setdefaultModal(false)}>
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <FormGroup>
                              <Label className="form-control-label" htmlFor="example-search-input">
                                Mã sản phẩm
                              </Label>
                              <Select2
                                className="form-control"
                                defaultValue="-1"
                                value={optionSanPhamCopy}
                                options={{ placeholder: 'Chọn sản phẩm' }}
                                data={optionsSanPham.map((item) => ({ id: item.maSanPham, text: item.tenSanPham }))}
                                onSelect={(e: any) => {
                                  setOptionSanPhamCopy(e.params.data.id);
                                }}
                              />
                            </FormGroup>
                          </div>
                          <div className="modal-footer">
                            <Button color="primary" type="button" onClick={handleCopyTieuChuan}>
                              Đồng ý
                            </Button>
                            <Button
                              className="ml-auto"
                              color="link"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => setdefaultModal(false)}>
                              Huỷ
                            </Button>
                          </div>
                        </Modal>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="content-box">
                    <Card>
                      <CardBody className="p-4" style={{ overflowY: 'auto' }}>
                        <FormGroup className="row">
                          <Label className="form-control-label" htmlFor="example-search-input" md="2">
                            PDF khả dụng
                          </Label>
                          <Col md="10">
                            <Select2
                              className="form-control"
                              defaultValue="-1"
                              value={optionPDF}
                              options={{ placeholder: 'Chọn pdf có sẵn' }}
                              data={optionsPDF}
                              onSelect={(e: any) => {
                                setFile(e.params.data.id);
                                setOptionPDF(e.params.data.id);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <div className="Example">
                          <div className="Example__container">
                            <div className="Example__container__document" ref={setContainerRef}>
                              {fileLoaded && (
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)}
                                    disabled={pageNumber === 1}>
                                    Trang trước
                                  </button>
                                  <p className="m-0 text-center">
                                    Trang <b>{pageNumber}</b> / <b>{numPages}</b>
                                  </p>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)}
                                    disabled={pageNumber === numPages}>
                                    Trang kế tiếp
                                  </button>
                                </div>
                              )}
                              <Document
                                onWheel={handleWheel}
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}>
                                <Page
                                  pageNumber={pageNumber}
                                  scale={scale}
                                  width={containerWidth ? Math.min(containerWidth, maxWidthPDF) : maxWidthPDF}
                                />
                              </Document>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col> */}
              </Row>
            )}
          </Container>
        </BasicTemplate>
      )}
    </>
  );
}

export default PKTCS_NhapLieuTieuChuan;
