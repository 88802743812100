import { Page } from 'main/constants';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }: PropsWithChildren) {
  const authenticated = true;
  // const { authenticated } = useAuth();

  return authenticated ? <>{children}</> : <Navigate to={Page.LOGIN.PATH} replace />;
}

export default PrivateRoute;
