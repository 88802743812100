/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * ktcs-api
 * ktcs-api
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AllChiTieu,
  CreateNewVersion,
  TongHopTieuChuanControllerCopyTieuChuanBody,
  TongHopTieuChuanControllerUploadFile200,
  TongHopTieuChuanControllerUploadFileBody,
} from '../../models';
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';

export const tongHopTieuChuanControllerGetAllChiTieu = (signal?: AbortSignal) => {
  return drcInstance<AllChiTieu[]>({ url: `/all-chi-tieu`, method: 'GET', signal });
};

export const getTongHopTieuChuanControllerGetAllChiTieuQueryKey = () => {
  return [`/all-chi-tieu`] as const;
};

export const getTongHopTieuChuanControllerGetAllChiTieuQueryOptions = <
  TData = Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTongHopTieuChuanControllerGetAllChiTieuQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>> = ({ signal }) =>
    tongHopTieuChuanControllerGetAllChiTieu(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TongHopTieuChuanControllerGetAllChiTieuQueryResult = NonNullable<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>
>;
export type TongHopTieuChuanControllerGetAllChiTieuQueryError = ErrorType<unknown>;

export const useTongHopTieuChuanControllerGetAllChiTieu = <
  TData = Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof tongHopTieuChuanControllerGetAllChiTieu>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTongHopTieuChuanControllerGetAllChiTieuQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tongHopTieuChuanControllerCopyTieuChuan = (
  tongHopTieuChuanControllerCopyTieuChuanBody: TongHopTieuChuanControllerCopyTieuChuanBody,
) => {
  return drcInstance<unknown>({
    url: `/copy-tieu-chuan`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: tongHopTieuChuanControllerCopyTieuChuanBody,
  });
};

export const getTongHopTieuChuanControllerCopyTieuChuanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerCopyTieuChuan>>,
    TError,
    { data: TongHopTieuChuanControllerCopyTieuChuanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerCopyTieuChuan>>,
  TError,
  { data: TongHopTieuChuanControllerCopyTieuChuanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerCopyTieuChuan>>,
    { data: TongHopTieuChuanControllerCopyTieuChuanBody }
  > = (props) => {
    const { data } = props ?? {};

    return tongHopTieuChuanControllerCopyTieuChuan(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TongHopTieuChuanControllerCopyTieuChuanMutationResult = NonNullable<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerCopyTieuChuan>>
>;
export type TongHopTieuChuanControllerCopyTieuChuanMutationBody = TongHopTieuChuanControllerCopyTieuChuanBody;
export type TongHopTieuChuanControllerCopyTieuChuanMutationError = ErrorType<unknown>;

export const useTongHopTieuChuanControllerCopyTieuChuan = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerCopyTieuChuan>>,
    TError,
    { data: TongHopTieuChuanControllerCopyTieuChuanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerCopyTieuChuan>>,
  TError,
  { data: TongHopTieuChuanControllerCopyTieuChuanBody },
  TContext
> => {
  const mutationOptions = getTongHopTieuChuanControllerCopyTieuChuanMutationOptions(options);

  return useMutation(mutationOptions);
};
export const tongHopTieuChuanControllerInsertUpdateVersion = (createNewVersion: CreateNewVersion) => {
  return drcInstance<CreateNewVersion>({
    url: `/insert-update-version`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createNewVersion,
  });
};

export const getTongHopTieuChuanControllerInsertUpdateVersionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerInsertUpdateVersion>>,
    TError,
    { data: CreateNewVersion },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerInsertUpdateVersion>>,
  TError,
  { data: CreateNewVersion },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerInsertUpdateVersion>>,
    { data: CreateNewVersion }
  > = (props) => {
    const { data } = props ?? {};

    return tongHopTieuChuanControllerInsertUpdateVersion(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TongHopTieuChuanControllerInsertUpdateVersionMutationResult = NonNullable<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerInsertUpdateVersion>>
>;
export type TongHopTieuChuanControllerInsertUpdateVersionMutationBody = CreateNewVersion;
export type TongHopTieuChuanControllerInsertUpdateVersionMutationError = ErrorType<unknown>;

export const useTongHopTieuChuanControllerInsertUpdateVersion = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerInsertUpdateVersion>>,
    TError,
    { data: CreateNewVersion },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerInsertUpdateVersion>>,
  TError,
  { data: CreateNewVersion },
  TContext
> => {
  const mutationOptions = getTongHopTieuChuanControllerInsertUpdateVersionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const tongHopTieuChuanControllerGetTieuChuanByMaSanPham = (maSanPham: string, signal?: AbortSignal) => {
  return drcInstance<CreateNewVersion>({ url: `/tieu-chuan/${maSanPham}`, method: 'GET', signal });
};

export const getTongHopTieuChuanControllerGetTieuChuanByMaSanPhamQueryKey = (maSanPham: string) => {
  return [`/tieu-chuan/${maSanPham}`] as const;
};

export const getTongHopTieuChuanControllerGetTieuChuanByMaSanPhamQueryOptions = <
  TData = Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>,
  TError = ErrorType<unknown>,
>(
  maSanPham: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTongHopTieuChuanControllerGetTieuChuanByMaSanPhamQueryKey(maSanPham);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>> = ({
    signal,
  }) => tongHopTieuChuanControllerGetTieuChuanByMaSanPham(maSanPham, signal);

  return { queryKey, queryFn, enabled: !!maSanPham, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TongHopTieuChuanControllerGetTieuChuanByMaSanPhamQueryResult = NonNullable<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>
>;
export type TongHopTieuChuanControllerGetTieuChuanByMaSanPhamQueryError = ErrorType<unknown>;

export const useTongHopTieuChuanControllerGetTieuChuanByMaSanPham = <
  TData = Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>,
  TError = ErrorType<unknown>,
>(
  maSanPham: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tongHopTieuChuanControllerGetTieuChuanByMaSanPham>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTongHopTieuChuanControllerGetTieuChuanByMaSanPhamQueryOptions(maSanPham, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tongHopTieuChuanControllerUploadFile = (
  tongHopTieuChuanControllerUploadFileBody: TongHopTieuChuanControllerUploadFileBody,
) => {
  const formData = new FormData();
  if (tongHopTieuChuanControllerUploadFileBody.file !== undefined) {
    formData.append('file', tongHopTieuChuanControllerUploadFileBody.file);
  }

  return drcInstance<TongHopTieuChuanControllerUploadFile200>({
    url: `/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getTongHopTieuChuanControllerUploadFileMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerUploadFile>>,
    TError,
    { data: TongHopTieuChuanControllerUploadFileBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerUploadFile>>,
  TError,
  { data: TongHopTieuChuanControllerUploadFileBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerUploadFile>>,
    { data: TongHopTieuChuanControllerUploadFileBody }
  > = (props) => {
    const { data } = props ?? {};

    return tongHopTieuChuanControllerUploadFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TongHopTieuChuanControllerUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerUploadFile>>
>;
export type TongHopTieuChuanControllerUploadFileMutationBody = TongHopTieuChuanControllerUploadFileBody;
export type TongHopTieuChuanControllerUploadFileMutationError = ErrorType<unknown>;

export const useTongHopTieuChuanControllerUploadFile = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tongHopTieuChuanControllerUploadFile>>,
    TError,
    { data: TongHopTieuChuanControllerUploadFileBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof tongHopTieuChuanControllerUploadFile>>,
  TError,
  { data: TongHopTieuChuanControllerUploadFileBody },
  TContext
> => {
  const mutationOptions = getTongHopTieuChuanControllerUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};
