import axios from 'axios';
import page from 'main/constants/page';
import { AuthenticationUtil } from 'main/utils';
import Endpoint from './endpoints';

export const drcAxios = axios.create({
  baseURL: Endpoint.DrcApiBaseUrl,
  timeout: 10000,
});

drcAxios.interceptors.request.use(
  (config) => {
    const token = AuthenticationUtil.getAuthToken();
    if (token) {
      // @ts-ignore
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error as Error);
  },
);

drcAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.url === '/users/login') {
      return Promise.reject(error as Error);
    }

    if (error.response && error.response.status === 401) {
      window.location.href = page.LOGIN.PATH;
    }
    if (error.response && error.response.status === 403) {
      window.location.href = page.ACCESS_DENIED.PATH;
    }

    return Promise.reject(error as Error);
  },
);
