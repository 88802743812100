import AdminFooter from 'main/components/organisms/footer/admin-footer';
import AdminNavbar from 'main/components/organisms/navbar/admin-navbar';
import Sidebar from 'main/components/organisms/sidebar/sidebar';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { scrollTop } from './auth';

const menuRoutes = [
  {
    collapse: true,
    name: 'Phòng kỹ thuật cao su',
    icon: 'ni ni-single-copy-04 text-pink',
    views: [
      {
        path: '/phong-ky-thuat-cao-su/nhap-lieu-tieu-chuan',
        name: 'Nhập liệu tiêu chuẩn',
        miniName: 'NL',
        layout: '/admin',
      },
    ],
  },
  // {
  //   collapse: false,
  //   name: 'Phòng kỹ thuật cao su',
  //   icon: 'ni ni-shop text-primary',
  //   path: '/phong-ky-thuat-cao-su',
  //   miniName: 'D',
  //   layout: '/admin',
  // },
  // {
  //   collapse: true,
  //   name: 'Quản lý Kho vật tư',
  //   icon: 'ni ni-single-copy-04 text-pink',
  //   state: 'suppliesStoreManagementCollapse',
  //   views: [
  //     {
  //       path: '/supplies-store-import/list',
  //       name: 'Quản lý nhập kho vật tư',
  //       miniName: 'SI',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/supplies-store-export/list',
  //       name: 'Quản lý xuất kho vật tư',
  //       miniName: 'SE',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/supplies-store-inventory/list',
  //       name: 'Quản lý tồn kho vật tư',
  //       miniName: 'SX',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/supplies-store-inventory/warning',
  //       name: 'Cảnh báo tồn kho',
  //       miniName: 'SW',
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Danh mục vật tư',
  //   icon: 'ni ni-ui-04 text-info',
  //   state: 'suppliesItemManagementCollapse',
  //   views: [
  //     {
  //       path: '/supplies-management/list',
  //       name: 'Danh mục quản lý vật tư',
  //       miniName: 'M',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/supplies-warehouse/list',
  //       name: 'Danh mục kho vật tư',
  //       miniName: 'W',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/supplies-group/list',
  //       name: 'Danh mục nhóm vật tư',
  //       miniName: 'G',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/supplies-origin/list',
  //       name: 'Danh mục nguồn gốc vật tư',
  //       miniName: 'O',
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Quản lý Kho sản phẩm',
  //   icon: 'ni ni-ungroup text-orange',
  //   state: 'productStoreManagementCollapse',
  //   views: [
  //     {
  //       path: '/product-store-import/list',
  //       name: 'Quản lý nhập kho sản phẩm',
  //       miniName: 'PI',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product-store-export/list',
  //       name: 'Quản lý xuất kho sản phẩm',
  //       miniName: 'PE',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product-store-inventory/list',
  //       name: 'Quản lý tồn kho sản phẩm',
  //       miniName: 'PX',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product-store-inventory/warning',
  //       name: 'Cảnh báo tồn kho',
  //       miniName: 'PW',
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Danh mục sản phẩm',
  //   icon: 'ni ni-align-left-2 text-default',
  //   state: 'productItemManagementCollapse',
  //   views: [
  //     {
  //       path: '/product-warehouse/list',
  //       name: 'Quản lý kho thành phẩm',
  //       miniName: 'PI',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product-enterprise/list',
  //       name: 'Danh mục đơn vị xí nghiệp',
  //       miniName: 'PE',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product-group/list',
  //       name: 'Danh mục nhóm sản phẩm',
  //       miniName: 'PG',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product/list',
  //       name: 'Danh mục sản phẩm',
  //       miniName: 'PP',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/product-customer/list',
  //       name: 'Danh mục khách hàng',
  //       miniName: 'PK',
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: false,
  //   name: 'Quy định và hướng dẫn',
  //   icon: 'ni ni-calendar-grid-58 text-red',
  //   path: '/terms-and-conditions/list',
  //   miniName: 'TC',
  //   layout: '/admin',
  // },
  // {
  //   collapse: true,
  //   name: 'Quản lý hệ thống',
  //   icon: 'ni ni-settings text-purple',
  //   state: 'userManagementCollapse',
  //   views: [
  //     {
  //       path: '/user/list',
  //       name: 'Quản lý người dùng',
  //       miniName: 'USER',
  //       layout: '/admin',
  //     },
  //   ],
  // },
];

function AdminLayout() {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollTop(mainContentRef);
  }, [location]);

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  return (
    <>
      <Sidebar
        routes={menuRoutes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/',
          imgSrc: `${process.env.PUBLIC_URL}/assets/img/logo-drc.png`,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar theme={getNavbarTheme()} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />

        <Outlet />

        {/* <AdminFooter /> */}
      </div>
      {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
    </>
  );
}

export default AdminLayout;
